import React, { useState, useEffect, useRef } from 'react';
import { toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Toastify CSS
import { ToastContainer } from 'react-toastify';
import '../css/Contact_us.css';
import '../css/Contact_us_responsive.css'; 
import copy_icon from '../images/Copy Icon.png';
import Shevron_icon from '../images/Shevron.png';
import location_icon from '../images/Location Icon.png';
import MapComponent from '../Components/map';
const ContactUs = () => {

  const [copyMessage, setCopyMessage] = useState('');

  const copyToClipboard = (emailId, copyIconId) => {
    const email = document.getElementById(emailId).innerText;
    navigator.clipboard.writeText(email)
      .then(() => {
        // Show success toast message
        toast.success('Email copied successfully!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });

        // Show copy message for 2 seconds
        setCopyMessage(`Email copied from ${emailId}`);
        setTimeout(() => setCopyMessage(''), 2000);
      })
      .catch(() => {
        // Show error toast message if copying fails
        toast.error('Failed to copy email!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
      });
  };

  const [formData, setFormData] = useState({
    email: '',
    fullName: '',
    subject: '',
    orderId: '',
    message: '',
  });

  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Select an option');
  const dropdownRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    toast.success('Form submitted successfully!', {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });

    console.log('Form submitted: ', formData);

    setFormData({
      email: '',
      fullName: '',
      subject: '',
      orderId: '',
      message: '',
    });

    setSelectedOption('Select an option');
  };

  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setFormData({ ...formData, subject: option });
    setDropdownVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <>
      {/* Toast container */}
      <ToastContainer />

      {/* Contact_CoinoSwap section start */}
      <section className='contact_coinoSwap'>
        <div className="container-fluid">
          <h4 className='contact_coinoSwap_heading contact_heading'>Contact <strong>CoinoSwap</strong></h4>
        </div>
      </section>
      {/* Contact_CoinoSwap section end */}
      {/* Rest of your JSX goes here */}

      {/* Get In Touch section start */}
      <div className="container-fluid mt-4 getIn_touch_section">
        <h3 className="text-center contact_heading getIn_touch">Get In Touch</h3>
        <div className="row mt-5">
          <div className="col-lg-3 col-md-12 text-left getIn_touch_cols">
            <p className='contact_para contact_links copied_heading'>PR & Marketing:</p>
            <span id="pr-email" className='contact_heading copied_mail'>pr@coinoswap.com</span>
            <img 
              className='copied_icon'
              src={copy_icon} 
              alt="Copy Icon" 
              id="pr-copy-icon"
              onClick={() => copyToClipboard("pr-email", "pr-copy-icon")} 
            />
          </div>

          <div className="col-lg-3 col-md-12 text-left getIn_touch_cols">
            <p className='contact_para contact_links copied_heading'>Support:</p>
            <span id="support-email" className='contact_heading copied_mail'>Support@Coinswap.Com</span>
            <img 
              className='copied_icon'
              src={copy_icon} 
              alt="Copy Icon" 
              id="support-copy-icon"
              onClick={() => copyToClipboard("support-email","support-copy-icon")} 
            />
          </div>

          <div className="col-lg-3 col-md-12 text-left getIn_touch_cols">
            <p className='contact_para contact_links copied_heading'>Partnerships:</p>
            <span id="partnership-email" className='contact_heading copied_mail'>Partnerships@Coinswap.Com</span>
            <img 
              className='copied_icon'
              src={copy_icon} 
              alt="Copy Icon" 
              id="partnership-copy-icon"
              onClick={() => copyToClipboard("partnership-email","partnership-copy-icon")} 
            />
             {copyMessage && <span className="copy-message">{copyMessage}</span>}
          </div>

          <div className="col-lg-3 col-md-12 text-left getIn_touch_cols">
            <p className='contact_para contact_links'>Follow Us:</p>
            <div className='social-icon-div mt-4'>
              <div className='social-icons'><i className="fa-brands fa-x-twitter social-twitter"></i></div>
              <div className='social-icons'><i className="fa-brands fa-tiktok social-tiktok"></i></div>
              <div className='social-icons'><i className="fa-brands fa-instagram social-instagram"></i></div>
              <div className='social-icons'><i className="fa-brands fa-youtube social-youtube"></i></div>
              <div className='social-icons'><i className="fa-brands fa-facebook-f social-facebook"></i></div>
            </div>
          </div>
        </div>
      </div>
      {/* Get In Touch section end */}

      {/* Contact Form section start */}
      <section className='contact_form'>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 form_col">
            <div className="form-container">
              <h2 className='contact_heading contact-form_heading'>Contact Form</h2>
              <form onSubmit={handleSubmit} className='contcts_form'>
                {/* Email and Full Name */}
                <div className="form-row">
                  <div className="form-group">
                    <label>Email Address</label>
                    <input
                      className='userInfo_group'
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="name@example.com"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Full Name</label>
                    <input
                      className='userInfo_group'
                      type="text"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      placeholder="Full Name"
                      required
                    />
                  </div>
                </div>

                {/* Subject Dropdown */}
                <div className="custom-select-container" ref={dropdownRef}>
                  <label htmlFor="subject" className="custom-label">Subject</label>
                  <div className="custom-select">
                    {/* Display selected option on the left side */}
                    <span className="selected-option">{selectedOption}</span>

                    {/* Icon click toggles the dropdown */}
                    <img 
                      src={Shevron_icon} 
                      className="dropdown-icon" 
                      alt="dropdown-icon" 
                      onClick={toggleDropdown} 
                    />
                  </div>

                  {/* Dropdown Menu */}
                  {isDropdownVisible && (
                    <ul className="select_dropdown">
                      <li onClick={() => handleOptionSelect('Support')}>Support</li>
                      <li onClick={() => handleOptionSelect('Marketing & PR')}>Marketing & PR</li>
                      <li onClick={() => handleOptionSelect('Partnerships')}>Partnerships</li>
                      <li onClick={() => handleOptionSelect('Others')}>Others</li>
                    </ul>
                  )}
                </div>

                {/* Order Tracker ID */}
                <div className="form-group">
                  <label>Order Tracker ID</label>
                  <input
                    className='order_group'
                    type="text"
                    name="orderId"
                    value={formData.orderId}
                    onChange={handleChange}
                    placeholder="Enter Order Tracker ID"
                  />
                </div>

                {/* Message */}
                <div className="form-group">
                  <label>Message</label>
                  <textarea
                    className='message_group'
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Message"
                    required
                  />
                </div>

                {/* Submit Button */}
                <button type="submit" className="submit-btn">Send</button>
              </form>
            </div>
          </div>

          {/* Map Section */}
          <div className="col-lg-6 map_col">
            <h2 className='contact_heading map_heading'>Our Location</h2>
            <div className="google_map">
            <MapComponent />
            </div>
          </div>
        </div>
      </div>
    </section>
      {/* Contact Information section start */}
      <div className="contact_info_section">
        <div className="container-fluid">
          <div className="row text-center info_sec">
            <div className="col-lg-12">
              <h4 className='abou_heading contact_info_heading'>Contact Information</h4>
              <img src={location_icon} alt="Location" className='contact_info_img'/>
              <p className='contact_info_para about_para'>Legal Registered Address:</p>
              <h4 className='contact_info_adress abou_heading'>123 Smith Street, Dubai, UAE</h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
